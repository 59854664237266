/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, kbender
 */

import { Injectable } from "@angular/core";
import {AlertService} from "../../alert/alert.service";
import {AlertLevel, Companion, EndpointService, IEndpoint, LogUtil} from "companion";

@Injectable()
export class ClientLogService {

timer: any;

  constructor(private alertService: AlertService) {}

  /**
   * Send webRTC statistics every sendingFrequency milliseconds
   * @param sendingFrequency: Sending frequency in milliseconds
   */
  enableWebrtcStats(sendingFrequency: number) {
    clearTimeout(this.timer);
    if (sendingFrequency !== 0) {
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        // Look for VCC connections.
        let endpoints: Readonly<IEndpoint>[] = Companion.getConferenceService().currentActiveConference?.everyone;
        if (endpoints) {
          endpoints.forEach(ep => {
              Companion.getRTCService().getRawPeerConnectionStatistics(ep.rtcId).then((stats : any) => {
                const stat = {
                  time: new Date().toISOString(),
                  msg: stats,
                  type: "stat",
                  peerRtcId: ep.rtcId
                };
                LogUtil.getLogInstance().writeWebRTCStats(stat);
              })
              .catch((error) => {
                // no stats/connection yet?
              });
            });
          }

        this.enableWebrtcStats(sendingFrequency);
      }, sendingFrequency);
    }
  }

  /**
   * send logs to server
   */
  sendLogs(showAlert: boolean = false): Promise<any> {
    const logsPromises: Promise<any>[] = [LogUtil.getLogInstance().sendLogs()];
    if (LogUtil.getLogInstance().logsSettings.webrtcStatistics) {
      // send web RTC stats here.
      logsPromises.push(LogUtil.getLogInstance().sendWebRTCStats());
    }
    return Promise.all(logsPromises)
    .then((res) => {
      if (showAlert) {
        this.alertService.createAlert(null, "Logs sent successfully", AlertLevel.success);
      }

      return res;
    })
    .catch(error => {
      if (showAlert) {
        this.alertService.createAlert(null, "An error occurred while sending the logs", AlertLevel.warning);
      }
      throw error;
    });
  }
}
