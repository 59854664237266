/**
 * Copyright Compunetix Incorporated 2018-2020
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { LocalizationService } from "../../localization/localization.service";
import { Companion, IActiveConference, IEndpoint, IExpandedActiveConference, IMonitorConference } from "companion";

/**
 * File utility functions for VCC and XMCM 
 */
 export class FileUtility {

    /**
     * @param template a template string for the format to create filename with.
     * @param localizationService handle to the localization service for theme data.
     * @param fileExtension the file extension to append (Include the '.' with this string)
     */
    static generateFileName(template: string, localizationService: LocalizationService, fileExtension?: string) : string
    {
        let conferenceService = Companion.getConferenceService();
        let conference : IExpandedActiveConference = conferenceService.currentActiveConference;
        let myEndpoint : IEndpoint = Companion.getEndpointService().myEndpoint;

        let today = new Date();
        let dateNow: string =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1 > 9 ? "" : "0") +
          (today.getMonth() + 1) +
          "-" +
          (today.getDate() > 9 ? "" : "0") +
          today.getDate();
        let timeNow: string =
          (today.getHours() > 9 ? "" : "0") +
          today.getHours() +
          "-" +
          (today.getMinutes() > 9 ? "" : "0") +
          today.getMinutes() +
          "-" +
          (today.getSeconds() > 9 ? "" : "0") +
          today.getSeconds();
        let endpointNames: string[];
        endpointNames = _.compact(
          _.map(conference?.active, (ep: IEndpoint) => {
            if (ep === myEndpoint) {
              return null;
            }
            return ep?.uiName || ep?.name || ep?.rtcId;
          })
        )
        _.forEach([...conferenceService.monitors.values()], (monConf: IMonitorConference) => {
          _.forEach(monConf.subjects, (epRef) => {
            endpointNames.push(epRef?.name || epRef?.rtcId);
          });
        });
        let remoteNames: string = _.join(endpointNames, "_").replace(/%20/g, " ");
       
        let localName = Companion.getEndpointService().myEndpoint.name.replace(/%20/g, " ");
  
        var filename: string = template;
        filename = filename.replace(/\[DATE\]/gi, dateNow);
        filename = filename.replace(/\[TIME\]/gi, timeNow);
        filename = filename.replace(/\[REMOTE_NAME\]/gi, remoteNames);
        filename = filename.replace(/\[LOCAL_NAME\]/gi, localName);
        filename = filename.replace(/\[NAME\]/gi, localName);
        filename = filename.replace(/\[CONFERENCE_NAME\]/gi, conference?.name || 'MON');
        filename = filename.replace(/\[CONFERENCE\]/gi, conference?.name || 'MON');
        
        if(fileExtension)
        {
          filename += fileExtension;
        }

        return filename;
  
    }
 }
