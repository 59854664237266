/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { AlertBoxComponent } from "./alert-box.component";
import { AlertService } from "./alert.service";

@NgModule({
  imports: [BrowserModule, FormsModule],
  declarations: [AlertBoxComponent],
  providers: [AlertService],
  exports: [AlertBoxComponent]
})
export class AlertModule {}
