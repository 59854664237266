/**
 * Copyright Compunetix Incorporated 2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { ColorPickerModule } from "ngx-color-picker";

import { SharedModule } from "../shared/shared.module";
import { FormModule } from "../form/form.module";

import { ConfigComponent } from "./config.component";

import { ConfigService } from "./config.service";
import { LayoutModule } from "../layout/layout.module";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    ColorPickerModule,
    SharedModule,
    FormModule,
    LayoutModule
  ],
  declarations: [ConfigComponent],
  providers: [ConfigService],
  exports: [ConfigComponent]
})
export class ConfigModule {}
