/**
 * Copyright Compunetix Incorporated 2016-2017
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component } from "@angular/core";
import { AlertService } from "./alert.service";
import { LocalizationService } from "../localization/localization.service";
import { AlertLevel, IAlert } from "companion";

@Component({
  selector: "alert-box",
  templateUrl: "alert-box.template.html"
})

/**
 * alert box component
 */
export class AlertBoxComponent {
  public AlertLevel = AlertLevel;
  constructor(public alertService: AlertService, public localizationService: LocalizationService) {}

  getAlertMessageText(alert: IAlert): string {
    let result: string = "";
    result = alert.alertText || alert.alertCode || result;
    if (this.localizationService.myLocalizationData?.errorMessages?.[alert.alertCode]) {
      result = this.localizationService.myLocalizationData.errorMessages[alert.alertCode];
    }
    if (alert.alertEnd) {
      let secondsLeft: number = Math.floor((alert.alertEnd.getTime() - new Date().getTime()) / 1000);
      if (secondsLeft > 0) {
        result = result.toString().replace(/\[count_down\]/gmi, secondsLeft + "");
      } else {
        result = result.toString().replace(/\[count_down\]/gmi, "0");
      }
    }
    return result;
  }
  /**
   * remove this alert from view
   * @param alert: IAlert - the alert object
   */
  removeAlert(alert: IAlert) {
    this.alertService.delete(alert);
  }
}
