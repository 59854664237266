/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, ViewChild, ElementRef, OnInit, Input } from "@angular/core";
import { NgProgressComponent } from "ngx-progressbar";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { HelperService } from "./helper.service";
import { VolumeMeterComponent } from "../shared/components/volume-meter/volume-meter.component";
import { LocalizationService } from "../localization/localization.service";

/**
 * WIZARD STEPS 
 */
enum WizardStep
{
  Start, // UNUSED currently
  BrowserTest, // UNUSED
  CameraTest, // start here for now
  SpeakerTest,
  // NetworkTest, // IGNORED for now
  Finished,
}

@Component({
  selector: "helper-wizard",
  templateUrl: "./helper-wizard.component.html"
})

/**
 * helper wizard component
 */
export class HelperWizardComponent implements OnInit {

  /**
   * The current step the wizard is ON
   */
  testerStep : WizardStep = WizardStep.CameraTest;

  public wizardStepClass = WizardStep;

  /**
   * the data model for this component view
   */
  viewModel: any = {
    tests: {
      browser: { enabled: false },
      camera: { enabled: true },
      microphone: { enabled: true },
      speaker: { enabled: true, autoStartNextStep: "microphone" },
      network: { enabled: true }
    }
  };

  /**
   * constructor of component
   * @param helperService: HelperService - helper service object served through dependency injection
   * @param localizationService: LocalizationService - localization service object served through dependency injection
   */
  constructor(private helperService: HelperService, public localizationService: LocalizationService) {}

  @Input() isEmbeded: boolean;

  /**
   * the sub component progress bar
   */
  @ViewChild(NgProgressComponent)
  progressBar: NgProgressComponent;

  /**
   * the sub component pip video element
   */
  @ViewChild("pipVideo")
  pip: ElementRef;

  /**
   * the sub component volume meter
   */
  @ViewChild(VolumeMeterComponent)
  micVolumeMeter: VolumeMeterComponent;

  /**
   * component init event handler
   * set up default handlers and check if network test should be enabled
   */
  ngOnInit() {
    this.viewModel.tests.browser.testCase = this.helperService.testBrowserPromise.bind(this.helperService);
    this.viewModel.tests.camera.testCase = this.helperService.testCameraPromise.bind(this.helperService);
    this.viewModel.tests.microphone.testCase = this.helperService.testMicrophonePromise.bind(this.helperService);
    this.viewModel.tests.speaker.testCase = this.helperService.testSpeakerPromise.bind(this.helperService);

    this.viewModel.tests.browser.cancelTestCase = this.helperService.stopTestBrowser.bind(this.helperService);
    this.viewModel.tests.camera.cancelTestCase = this.helperService.stopTestCamera.bind(this.helperService);
    this.viewModel.tests.microphone.cancelTestCase = this.helperService.stopTestMicrophone.bind(this.helperService);
    this.viewModel.tests.speaker.cancelTestCase = this.helperService.stopTestSpeaker.bind(this.helperService);

    let resetFocusTimer = setTimeout(() => {
      clearTimeout(resetFocusTimer);
      if ($("#helper-wizard-body button")[0]) { $("#helper-wizard-body button")[0].focus(); }
    }, 2 * 1000);
  }

  /**
   * event handler on the last/finish step
   */
  finish() {
    Dispatcher.dispatch(ActionType.FinishHelperWizard);
  }

  /**
   * start test on the specific step
   * @param step: string - step keyword
   */
  startTest(step: string) {
    if (!this.viewModel.tests[step]) {
      return;
    }
    this.progressBar.start();
    this.viewModel.tests[step].started = true;
    this.viewModel.tests[step].isTesting = true;
    this.viewModel.tests[step]
      .testCase(this, step)
      .then((result: boolean) => {
        this.viewModel.tests[step].isTesting = false;
        this.viewModel.tests[step].isPassed = result;
        this.viewModel.tests[step].result = result ? "SUCCESS" : "FAIL";
      })
      .catch((error: Error) => {
        this.viewModel.tests[step].isPassed = false;
        this.viewModel.tests[step].result = error.message || "FAIL";
      })
      .then(() => {
        this.progressBar.complete();
        this.viewModel.tests[step].isTesting = false;
      })
      .then(() => {
        if (this.viewModel.tests[step].isPassed && this.viewModel.tests[step].autoStartNextStep) {
          this.startTest(this.viewModel.tests[step].autoStartNextStep);
        }
      });
    let resetFocusTimer = setTimeout(() => {
      clearTimeout(resetFocusTimer);
      if ($(".confirm-box")[0]) { $(".confirm-box")[0].focus(); }
    }, 1000);
  }

  /**
   * stop test on the specific step
   * @param step: string - step keyword
   */
  stopTest(step: string) {
    this.viewModel.tests[step].cancelTestCase(this);
    this.progressBar.complete();
    this.viewModel.tests[step].isTesting = false;
    this.viewModel.tests[step].isPassed = false;
    this.viewModel.tests[step].isConfirming = false;
    this.viewModel.tests[step].pendingUserAction = false;
    this.viewModel.tests[step].result = "Stopped";
  }

  /**
   * to print log message on screen
   * @param step: string - step keyword
   * @param log: string - log message
   */
  printLog(step: string, log: string) {
    this.viewModel.tests.network.logs = this.viewModel.tests.network.logs || [];
    this.viewModel.tests.network.logs.push(log);
  }

  /**
   * prompt a dialog for user to confirm
   * @param step: string - step keyword
   * @param messages: string[] - messages to be displayed on screen
   * @param hasNextOption: flag if there is another option to confirm
   */
  promptConfirmation(step: string, messages: string[], hasNextOption: boolean = false): Promise<boolean> {
    return new Promise((resolve: (result: boolean) => void, reject: (error: Error) => void) => {
      if (!this.viewModel.tests[step].isTesting) {
        return Promise.reject(new Error("Stopped"));
      }
      this.viewModel.tests[step].isConfirming = true;
      this.viewModel.tests[step].confirmMessages = messages;
      this.viewModel.tests[step].hasNextOption = hasNextOption;
      this.viewModel.tests[step].confirm = (result: boolean) => {
        this.viewModel.tests[step].isConfirming = false;
        resolve(result);
      };
    });
  }

  /**
   * prompt a dialog for user to select on options
   * @param step: string - step keyword
   * @param messages: string[] - messages to be displayed on screen
   * @param options: {[key: strign]: string} - selection options
   */
  promptSelection(step: string, messages: string[], options: { [key: string]: string }) {
    return new Promise((resolve: (result: string) => void, reject: (error: Error) => void) => {
      if (!this.viewModel.tests[step].isTesting) {
        return Promise.reject(new Error("Stopped"));
      }
      this.viewModel.tests[step].pendingUserAction = true;
      this.viewModel.tests[step].promptMessages = messages;
      this.viewModel.tests[step].options = options;
      this.viewModel.tests[step].selectionChange = (result: string) => {
        this.viewModel.tests[step].pendingUserAction = false;
        resolve(result);
      };
    });
  }

  /**
   * Go to the next step..
   */
  nextStep()
  {
    if(this.testerStep != WizardStep.Finished)
    {
      this.testerStep++;
    }
  }

  /**
   * Return to previous step..
   */
  previousStep(){
    if(this.testerStep != WizardStep.Start)
    {
      this.testerStep--;
    }
  }
}
