/**
 * Copyright Compunetix Incorporated 2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  kbender
 */

/**
 * Utility for recording video streams
 */
import { EasyRTCService } from "companion";
import RecordRTC from "recordrtc";

export class RecordUtil {
  static recorders: any = {};
  /**
   * do record
   * @param stream: MediaStreamg - media stream to record
   * @param audioOnly: boolean - flag if only record audio.
   */
  static startRecord(stream: MediaStream, recordFileName: string, audioOnly: boolean = false, recordId?: string) {
    if (stream == null) {
      return;
    }
    recordId = recordId || stream.id;
    var options = {
      mimeType: audioOnly ? "audio/webm" : "video/webm;codecs=h264",
      audioBitsPerSecond: 64000,
      videoBitsPerSecond: EasyRTCService.getSharedInstance().rtcClient.sysPrimaryVideoBandwidth * 1000,
      timeSlice: 6e4, // 1 minute blobs
      ondataavailable: (blob: any) => {
        this.recorders[recordId].blobs.push(blob);
      }
    };
    this.recorders[recordId] = { blobs: [], options: options};
    this.recorders[recordId].recorder = RecordRTC(stream, options);
    this.recorders[recordId].recorder.startRecording();
    this.recorders[recordId].isRecording = true;
    this.recorders[recordId].recordFileName = recordFileName; 
  }

  /**
   * stop record
   * @param stream : MediaStreamg - media stream to record
   */
  static stopRecord(stream: MediaStream, recordId?: string): Promise<Blob> {
    return new Promise((resolve: (data: Blob) => void, reject: (error: Error) => void) => {
      if (!stream && !recordId) {
        return;
      }
      recordId = recordId || stream.id;
      if (this.recorders[recordId] && this.recorders[recordId].recorder) {
        this.recorders[recordId].recorder.stopRecording((url: string, type: string) => {
          this.recorders[recordId].isRecording = false;
          resolve(
              this.recorders[recordId].recorder.getBlob() ||
                new Blob(this.recorders[recordId].blobs, { type: this.recorders[recordId].options.mimeType })
          );
          this.recorders[recordId] = null;
        });
      }
    });
  }

  static recordForAFewSeconds(stream: MediaStream, seconds: number, audioOnly: boolean = false, recordId?: string): Promise<Blob> {
    return new Promise((resolve: (data: Blob) => void, reject: (error: Error) => void) => {
      if (!stream && !recordId) {
        return;
      }
      recordId = recordId || stream.id;
      this.startRecord(stream, null, audioOnly, recordId);
      let recordTimer = setTimeout(() => {
        clearTimeout(recordTimer);
        this.stopRecord(stream, recordId)
        .then((data: Blob) => {
          resolve(data);
        })
        .catch((error: Error) => {
          reject(error);
        });
      }, seconds * 1000);
    });
  }

  /**
   * pause recording
   */
  static pauseRecord(stream: MediaStream, recordId?: string) {
    if (!stream && !recordId) {
      return;
    }
    recordId = recordId || stream.id;
    if (this.recorders[recordId] && this.recorders[recordId].recorder) {
      this.recorders[recordId].recorder.pauseRecording();
    }
  }

  /**
   * resume recording
   */
  static resumeRecord(stream: MediaStream, recordId?: string) {
    if (!stream && !recordId) {
      return;
    }
    recordId = recordId || stream.id;
    if (this.recorders[recordId] && this.recorders[recordId].recorder) {
      this.recorders[recordId].recorder.resumeRecording();
    }
  }
}