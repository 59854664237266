/**
 * Copyright Compunetix Incorporated 2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Dispatcher, ActionType } from "../shared/services/dispatcher";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "helper",
  templateUrl: "./helper.component.html"
})

/**
 * helper component
 */
export class HelperComponent implements AfterViewInit {
  /**
   * redirect url on exit
   */
  exitUrl: string;

  constructor(private route: ActivatedRoute) {}

  /**
   * handler on component after view init
   */
  ngAfterViewInit() {
    Dispatcher.dispatch(ActionType.OpenModalDialog, "helper-wizard", "helper-wizard");
    this.exitUrl = this.route.snapshot.queryParams["leave_redirect_url"];
    Dispatcher.register(ActionType.FinishHelperWizard, () => {
      if (this.exitUrl) {
        window.location.href = this.exitUrl;
      } else {
        if (window.opener) {
          window.close();
        }
      }
    });
  }
}
